import Vue from 'vue';
import App from './App';
import { router } from './router';
import { List, Cell, Form, Field, Icon, Button, Empty, Toast, Popup, NavBar, TreeSelect, Row, Col, Dialog, Radio, RadioGroup } from 'vant'
import store from '@/store'
import { Select, Option, Cascader, Table, TableColumn } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2'
// 时间格式化
import moment from 'moment'
moment.locale('zh-cn');
Vue.prototype.moment = moment;
Vue.use(List).use(Cell).use(Form).use(Field).use(Icon).use(Button).use(Empty).use(Toast).use(Popup).use(Select).use(Option).use(Cascader).use(NavBar).use(TreeSelect).use(Row).use(Col).use(Table).use(TableColumn).use(Dialog).use(Radio).use(RadioGroup).use(VueClipboard)

new Vue({
  store,
  router,
  el: '#app',
  render: h => h(App)
});
