import Cookies from 'js-cookie'

const prefix = "AFT-LIVE-CRM-";

export default {
  setCookies: (key, val, expires) => {
    Cookies.set(prefix + key, val, { expires: expires });
  },
  getCookies: key => {
    return Cookies.get(prefix + key);
  },
  removeCookies: key => {
    Cookies.remove(prefix + key);
  }
}
