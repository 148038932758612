import Vue from 'vue';
import cookies from '@/utils/cookies'
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: "home",
    component: () => import("./view/home"),
    meta: {
      title: "直播CRM首页"
    }
  },
  {
    path: '/user-list',
    name: "user_list",
    component: () => import("./view/user_list"),
    meta: {
      title: "用户列表",
      keepAlive: true
    }
  },
  {
    path: '/user-detail',
    name: "user_detail",
    component: () => import("./view/user_detail"),
    meta: {
      title: "潜客画像"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./view/login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('./view/statistics'),
    meta: {
      title: '回访统计'
    }
  },
  {
    name: '404',
    component: () => import('./view/404'),
    meta: {
      title: '404'
    }
  },
  {
    name: 'user',
    component: () => import('./view/user'),
    meta: {
      title: '会员中心'
    }
  },
  {
    path: '*',
    redirect: '/404'
  },
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
});
const whiteList = ['login', '404']
router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  // 控制逻辑
  if (whiteList.indexOf(to.name) > -1) {
    next();
  } else {
    const token = cookies.getCookies('token')
    if (token) {
      // the system has token, we need checked where the route is goging to.
      // if the route is going to login, we should redirect to home page
      if (to.name === 'login') {
        next({ name: "home" })
      } else {
        next()
      }
    } else {
      next({ name: 'login' })
    }
  }

});
export default router

export {
  router
};

